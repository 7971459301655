// @flow
const ebayDomains = {
  en: 'com',
  ru: 'com',
  es: 'es',
  pt: 'com',
  fr: 'fr',
  it: 'it',
};

const getEbayDomain = (locale: string = 'en'): string =>
  `https://www.ebay.${ebayDomains[locale] || 'com'}`;

export default {
  signIn: '/auth/ebay?origin=%2Fstock',
  faq: (locale?: string): string => {
    // faq link setting
    switch (locale) {
      case 'ja':
        return 'https://ebay.co.jp/ebaymag-faq';
      case 'ko':
        return 'https://www.ebay.co.kr/ebaymag-faq';
      case 'en':
        return 'https://help.ebaymag.com/en/articles/7941255-frequently-asked-questions';
      case 'es':
        return 'https://help.ebaymag.com/es/articles/7944505-preguntas-frecuentes';
      case 'zh':
        return 'https://help.ebaymag.com/zh-CN/articles/7941255-%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98';
      case 'ru':
        return 'https://help.ebaymag.com/ru/articles/7944507-%D1%87%D0%B0%D1%81%D1%82%D0%BE-%D0%B7%D0%B0%D0%B4%D0%B0%D0%B2%D0%B0%D0%B5%D0%BC%D1%8B%D0%B5-%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D1%8B';
      case 'it':
        return 'https://help.ebaymag.com/it/articles/7944503-domande-frequenti';
      case 'fr':
        return 'https://help.ebaymag.com/fr/articles/7944504-questions-frequemment-posees';
      default:
        return 'https://help.ebaymag.com/en/articles/7941255-frequently-asked-questions';
    }
  },
  helpCenter: (locale?: string): string => {
    // help center link
    switch (locale) {
      case 'zh':
        return 'https://help.ebaymag.com/zh-CN/';
      case 'ja':
        return 'https://help.ebaymag.com/ja/';
      case 'fr':
        return 'https://help.ebaymag.com/fr/';
      default:
        return 'https://help.ebaymag.com/en/';
    }
  },
  helpEbay: (locale: string): string => `${getEbayDomain(locale)}/help/home`,
  eportalLinks: {
    ja: 'https://eportal.ebay.co.jp/portals/inquiry_input',
    ko: 'https://e-portal.ebay.co.kr/portals/inquiry_input',
  },
  feedbackLinks: {
    ja: {
      holiday: 'https://help.ebaymag.com/ja/articles/4219600-ebaymag',
    },
  },
  moreAboutPaindInsertions: {
    ru: 'https://export.ebay.com/ru/fees-and-payments/seller-fees/insertion-fees',
    en: 'https://export.ebay.com/en/start-sell/selling-basics/seller-fees/insertion-fees',
    es: 'https://export.ebay.com/es/start-sell/seller-fees/insertion-fees',
    pt: 'https://export.ebay.com/pt/start-sell/seller-fees/insertion-fees',
  },
  dhl: 'https://export.ebay.com/ru/shipping/delivery-service-integration/dhl',
};
